import { definitions } from "@typings/supabase"
import * as R from "ramda"
import { isValidDiscount, applyDiscount, round } from "@utils/discounts"

type DiscountCode = definitions["discount_codes"]

export type PriceData = {
  pretaxPrice: number
  tax: number
  price: number
  currencyCode: string
  currencySymbol: string
  discount: number
}

/**
 * Returns a PriceData object based on a string from Contentful price dropdown.
 */
const getPriceData = (priceLabel: string): PriceData => {
  switch (priceLabel) {
    case "8,90€ (inc. VAT)":
      return {
        pretaxPrice: 709,
        tax: 25.5,
        price: 890,
        currencyCode: "EUR",
        currencySymbol: "€",
        discount: 0,
      }
    case "Half price (10€ inc. VAT)":
      return {
        pretaxPrice: 797,
        tax: 25.5,
        price: 1000,
        currencyCode: "EUR",
        currencySymbol: "€",
        discount: 0,
      }
    case "15,90€ (inc. VAT)":
      return {
        pretaxPrice: 1267,
        tax: 25.5,
        price: 1590,
        currencyCode: "EUR",
        currencySymbol: "€",
        discount: 0,
      }
    case "12,90€ (inc. VAT)":
      return {
        pretaxPrice: 1028,
        tax: 25.5,
        price: 1290,
        currencyCode: "EUR",
        currencySymbol: "€",
        discount: 0,
      }
    case "Gift Card":
      return {
        pretaxPrice: 1132,
        tax: 14,
        price: 1290,
        currencyCode: "EUR",
        currencySymbol: "€",
        discount: 0,
      }
    case "Free":
      return {
        pretaxPrice: 0,
        tax: 0,
        price: 0,
        currencyCode: "EUR",
        currencySymbol: "€",
        discount: 0,
      }
    case "Full price (20€ inc. VAT)":
    default:
      return {
        pretaxPrice: 1594,
        tax: 25.5,
        price: 2000,
        currencyCode: "EUR",
        currencySymbol: "€",
        discount: 0,
      }
  }
}

/**
 * Calculates price data based on a PriceData object and a DiscountCode object.
 * Product id is used in the isValidDiscount check to see ifdiscount is limited to
 * specific product id's only.
 * Does not _use_ a discount code. This is like a dryrun to get the discounted price.
 */
const getPrice = (
  price: PriceData,
  productId: string,
  discountOrMultiplier?: DiscountCode | number | undefined
): PriceData => {
  // Is discount provided? If not, return price.
  if (R.isNil(discountOrMultiplier)) {
    return price
  }

  if (typeof discountOrMultiplier == "number") {
    return _multiplyPrice(price, discountOrMultiplier)
  }

  // Is the discount valid? If not, return price.
  if (!isValidDiscount(discountOrMultiplier, productId)) {
    return price
  }

  // Discount is valid, apply it to the price.
  const discountedPrice = applyDiscount(price, discountOrMultiplier)

  return discountedPrice
}

/**
 * Internal method that multiples a price object and calculates
 * new price and pretaxPrice values for the object.
 */
const _multiplyPrice = (price: PriceData, multiplier: number): PriceData => {
  const newPriceInCents = round(price.price * multiplier, 0)
  const newPreTaxPriceInCents = round(
    newPriceInCents / (1 + price.tax / 100),
    0
  )

  return {
    ...price,
    pretaxPrice: newPreTaxPriceInCents,
    price: newPriceInCents,
  }
}

/**
 * Quality of life helper that converts euro cents to euros.
 */
const centsToEuros = (priceInCents: number): number => {
  return priceInCents / 100
}

/**
 * Helper to format the display price to the right format for the user's locale
 * @param locale string: client locale
 * @param currency string: price currency e.g EUR, USD
 * @param price number: price in cents
 * @returns string: formatted display price
 */
const getDisplayPrice = (
  locale: string,
  currency: string,
  price: number
): string => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(centsToEuros(price))
}

export { getPriceData, getPrice, centsToEuros, getDisplayPrice }
